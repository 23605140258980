import React, { ReactNode } from "react";
import { Flex, Heading, Stack } from "@chakra-ui/react";
import styled from "@emotion/styled";

interface HeroProps {
  title: ReactNode;
  subtitle: ReactNode;
  actions?: ReactNode;
  asset: ReactNode;
}

const StackContainer = styled(Stack)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    & .hero-asset {
      margin-left: 24px;
    }
  }
`;

const TextContainer = styled(Stack)`
  order: 1;
  @media (min-width: 768px) {
    order: 0;
  }
`;

export const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  actions,
  asset,
}) => {
  return (
    <StackContainer paddingTop={[16, 16, 32]} paddingBottom={32} spacing={0}>
      <TextContainer
        flex={1}
        direction="column"
        spacing={8}
        marginTop={[8, 8, 0]}
      >
        <Stack direction="column" spacing={4}>
          <Heading size="2xl" color="gray.800">
            {title}
          </Heading>
          <Heading size="lg" fontWeight="regular" color="gray.600">
            {subtitle}
          </Heading>
        </Stack>
        {actions}
      </TextContainer>
      <Flex
        flex={0.8}
        align="center"
        justify={["center", "center", "flex-end"]}
        className="hero-asset"
      >
        {asset}
      </Flex>
    </StackContainer>
  );
};
